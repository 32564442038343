'use strict';

angular.module('customerPortal').controller('PortalCustomersCtrl', ['$rootScope', '$scope', '$state', '$customerService', '$userService', '$timeout', 'currentCustomer', '$securityService', '$usersContextService', '$dateUtils', 'Notification', '$uibModal', '$helpChatService', '$translate', 'moreConstants', '$mobileService', '$modalService', '$customerUsageService', 'localStorageService', '$window', '$browserService', '$featureHelper', '$billingService', '$customerUserService', 'billingStatus', function ($rootScope, $scope, $state, $customerService, $userService, $timeout, currentCustomer, $securityService, $usersContextService, $dateUtils, Notification, $uibModal, $helpChatService, $translate, moreConstants, $mobileService, $modalService, $customerUsageService, localStorageService, $window, $browserService, $featureHelper, $billingService, $customerUserService, billingStatus) {
  const customerId = $state.params.customerId;
  const localStorageOverUsageKey = `banner.hideOverUsage.ci-${customerId}`;

  $rootScope.settings = {
    dateFormat: $dateUtils.formatDateFormat(currentCustomer.settings.dateFormat),
  };

  $scope.menuTemplate = require('../../../shared/templates/navbar.html');
  $scope.changeCustomer = changeCustomer;
  $scope.dismissAlert = dismissAlert;
  $scope.hasSession = hasSession;
  $scope.isMobileDevice = isMobileDevice;
  $scope.logout = logout;
  $scope.billingStatus = billingStatus;
  const isTrialing = $scope.billingStatus.trialing;
  $scope.encodedMailBody = $translate.instant('BANNER_PAYMENT_DISPUTE_MAIL_BODY').replaceAll('\n', '%0A');
  $scope.menuItems = getMenuItems();
  $scope.supplementaryParams = getSupplementaryParams();
  $scope.unlockedFeatures = currentCustomer.unlockedFeatures || [];
  $scope.showAlert = {
    mobile: true,
  };
  $scope.showBanner = showBanner;
  $scope.isScheduledForDelete = null;
  $scope.isBranded = $rootScope.features.isBranded;
  $scope.trialSubmissionLimit = moreConstants.TRIAL_SUBMISSION_LIMIT;
  $scope.isBranchOrUp = true;
  $rootScope.userStatus = null;
  $scope.getPriceBlogUrl = () => {
    const lang = $translate.use();
    switch (lang) {
      case 'nl':
        return 'https://moreapp.com/nl/blog/prijsmodel/';
      case 'de':
        return 'https://moreapp.com/de/blog/neue-preisgestaltung-moreapp/';
      case 'es':
      case 'ca':
        return 'https://moreapp.com/es/blog/precios/';
      case 'pt':
        return 'https://moreapp.com/pt-BR/blog/precos/';
      case 'fr':
        return 'https://moreapp.com/fr/blog/prix/';
      default:
        return 'https://moreapp.com/en/blog/pricing/';
    }
  };
  $scope.mailAccountOwner = () => {
    $customerUserService.getAccountOwner(customerId).$promise.then((owner) => {
      const subject = $translate.instant('BANNER_DELETION_MAIL_ACCOUNT_OWNER_SUBJECT');
      const body = $translate.instant('BANNER_DELETION_MAIL_ACCOUNT_OWNER_BODY', $scope.isScheduledForDelete);
      $window.open(`mailto:${owner.emailAddress}?subject=${subject}&body=${body}`, '_self');
    });
  };
  $scope.openBillingFlow = () => $billingService.openBillingFlow(isTrialing).then(() => {
    location.reload();
  });

  init();

  $scope.showMaintenanceBanner = () => false;

  $scope.showFreeTrialBanner = () => {
    return $scope.billingStatus.trialing && !$scope.features.isBranded;
  };

  $scope.showOverUsageBanner = () => {
    if ($scope.billingStatus.usageStats === undefined) {
      return false;
    }

    return $usersContextService.canManageBilling() && !isOverUsageBannerDismissed() && $scope.billingStatus.usageStats.nrOfSubmissionsOverIncluded > 0;
  };

  $scope.showPaymentDisputeBanner = () => {
      return $usersContextService.canManageBilling() && ($scope.billingStatus.blockedSubscription && $scope.billingStatus.disputedSubscription);
  };

  $scope.showHasUnpaidInvoicesBanner = () => {
    return $usersContextService.canManageBilling() && $scope.billingStatus.unpaidInvoices;
  };

  $scope.showHasPastDueInvoicesBanner = () => {
    return $usersContextService.canManageBilling() && $scope.billingStatus.pastDueInvoices;
  };

  $scope.showCreditCardExpiredBanner = () => {
    return $usersContextService.canManageBilling() && $scope.billingStatus.defaultPaymentMethodExpired;
  };

  function isOverUsageBannerDismissed() {
      const hideOverUsage = localStorageService.get(localStorageOverUsageKey);

      // Not dismissed
      if (hideOverUsage === null) {
          return false;
      }

      // Dismissal expired
      if (new Date() > new Date(hideOverUsage.expiry)) {
          localStorageService.remove(localStorageOverUsageKey);
          return false;
      }

      return true;
  }

  $scope.closeOverUsageBanner = () => {
    const { billingCycleEnd } = $scope.billingStatus.usageStats;

    localStorageService.set(localStorageOverUsageKey, {
      expiry: billingCycleEnd,
    });
  };

  ////////
  function init() {
    $scope.customers = $customerService.getCustomers('ALL');

    setCurrentCustomer(customerId);
    checkUserStatus();

    $userService.getUser().$promise.then(user => {
      if (window.posthog.get_distinct_id() !== user.id) {
        window.posthog.identify(user.id, { environment: window.MORE_ENV.environment });
      }
    });

    $featureHelper.hasFeature(moreConstants.HOOKS).then((feature) => {
      $scope.isBranchOrUp = feature;
    });

    $scope.$on('$stateChangeSuccess', function (event, toState) {
      if (toState.name.startsWith('portal.customers.forms') || toState.name.startsWith('portal.customers.applications')) {
        // Redirect 'FORMS' customers to F&F when accessing legacy URL's
        event.preventDefault();
        $state.go('portal.customers.form.overview');
      }
      if (toState.name.startsWith('portal.customers.users')) {
        // Redirect legacy user management URL's to new User Access URL's
        event.preventDefault();
        $state.go('portal.customers.access.users');
      }
      if (toState.name.startsWith('portal.customers.form.instructions')) {
        // Redirect legacy instruction management URL's to new User Access URL's
        event.preventDefault();
        $state.go('portal.customers.form.tasks');
      }
    });

    $scope.canManageBilling = $usersContextService.canManageBilling();

    // Redirect to a page to which the user has access if on root customer page
    if ($state.is('portal.customers')) {
      if ($usersContextService.canManageForms(customerId) || $usersContextService.canManageTasks(customerId) || $usersContextService.canManageRegistrations(customerId)) {
        $state.go('portal.customers.form.overview', {customerId});
      } else if ($usersContextService.canManageBilling(customerId)) {
        $state.go('portal.customers.billing.overview', {customerId});
      } else if ($usersContextService.canManageDataSources(customerId)) {
        $state.go('portal.customers.dataSources', {customerId});
      } else if ($usersContextService.canManageUsers(customerId) && ($scope.currentUser.permissions || $scope.currentUser.permissions.length !== 0)) {
        $state.go('portal.customers.access.users', {customerId});
      } else if ($usersContextService.canManageTemplates(customerId)) {
        $state.go('portal.customers.templates', {customerId});
      } else {
        $state.go('portal.insufficient_permissions');
      }
    }
  }

  function showBanner() {
    return $scope.isScheduledForDelete ||
        $scope.showFreeTrialBanner() ||
        $scope.showMaintenanceBanner() ||
        $scope.showOverUsageBanner() ||
        $scope.showPaymentDisputeBanner() ||
        $scope.showHasUnpaidInvoicesBanner() ||
        $scope.showHasPastDueInvoicesBanner() ||
        $scope.showCreditCardExpiredBanner();
  }

  function checkUserStatus() {
    if ($rootScope.userStatus === null && $scope.currentUser.roles) {
      $rootScope.userStatus = $userService.getUserStatus();
      $rootScope.userStatus.$promise.then(function (userStatus) {
        if (shouldShowTour()) {
          showTour();
        } else if (userStatus.customerTermsOfConditionsToAccept.length > 0 && userStatus.customerTermsOfConditionsToAccept.indexOf(parseInt(customerId)) !== -1) {
          $modalService.open({
            template: require('../../templates/portal/agreements.modal.html'),
            controller: 'AgreementsModalCtrl',
            controllerAs: 'ctrl',
            backdrop: 'static',
            keyboard: false
          });
        }
      });
    }
  }

  function setScheduledForDelete({meta}) {
      if (!meta || !meta.deleteOn) {
        // We need to reset to account for customer switching
        $scope.isScheduledForDelete = null;
        return;
      }

      const daysFromNow = (toDate) => Math.ceil((toDate - new Date()) / (1000 * 3600 * 24));
      const days = daysFromNow(new Date(meta.deleteOn));

      if (days >= 0) {
        $scope.isScheduledForDelete = {days}; // save as object for dynamic translation
      }
  }

  function setCurrentCustomer(customerId) {
    console.info(`Opening customer ${customerId}`);
    window.posthog.setPersonProperties({
      customer_id: customerId,
      is_branded: $rootScope.features.isBranded,
      branding: $rootScope.features.brandingKey,
    });

    $rootScope.customer = currentCustomer;
    currentCustomer.$promise.then(function (customer) {
      setScheduledForDelete(customer);
    });

    loadUsageStats();
  }

  function loadUsageStats() {
    $billingService.getUsage(customerId).$promise.then(usage => {
      const { cycle } = usage.plans.filter(p => p.type === 'BASE')[0];
      const submissions = usage.plans.filter(p => p.type === 'FORMS')[0];

      // !! Note, the code below assumes there are only ever two usage tiers
      const nrOfIncludedSubmissions = submissions.tiers.find((tier) => tier.upTo > 0).upTo;
      const nrOfSubmissionsOverIncluded = submissions.currentAmount - nrOfIncludedSubmissions;
      const pricePerExtraSubmissionInCents = submissions.tiers.find((tier) => tier.upTo === -1).usageAmount;

      $scope.billingStatus.usageStats = {
        billingCycleEnd: new Date(cycle.end * 1000),
        nrOfSubmissionsOverIncluded,
        extraUsageCostsInCents: nrOfSubmissionsOverIncluded * pricePerExtraSubmissionInCents
      };

      if (isTrialing) {
        $scope.billingStatus.trialStats = {
          daysLeft: Math.round((cycle.end - (new Date().getTime() / 1000)) / 24 / 60 / 60),
          submissionsLeft: $scope.trialSubmissionLimit - submissions.currentAmount,
        };
      }
    });
  }

  function hasSession() {
    return $securityService.isLoggedIn();
  }

  function isMobileDevice() {
    return $mobileService.isMobileDevice();
  }

  function dismissAlert(id) {
    $scope.showAlert[id] = false;
  }

  function hasPermissions(permissions) {
    return $scope.hasSession() &&
        ($usersContextService.hasRolesForCustomer($scope.currentUser, permissions, customerId) ||
            permissions.filter(permission => hasPermission(permission)).length > 0);
  }

  function hasPermission(allowedPermission) {
    if (!$scope.currentUser.permissions) {
      return false;
    }
    return $scope.currentUser.permissions.find(permissionGrant => permissionGrant.customerId === customerId &&
        permissionGrant.permissions.find(permission => permission === allowedPermission));
  }

  function logout() {
    $securityService.logout();
  }

  $scope.isLocked = item => $scope.unlockedFeatures.indexOf(item.feature) === -1;

  function changeCustomer(customer) {
    localStorageService.set('userState.currentCustomerId', customer.customerId);

    const paramsCopy = angular.copy($state.params);

    const toRoute = $state.current.name;
    paramsCopy.customerId = customer.customerId;
    paramsCopy.redirectState = toRoute;
    location.href = $state.href(toRoute, paramsCopy);
  }

  function getSupplementaryParams() {
    return {
      customerId() {
        return $scope.adminRoles()[0];
      }
    };
  }

  function showTour() {
    $uibModal.open({
      template: require('../../../modules/Tour/templates/tour.index.html'),
      controller: 'TourCtrl',
      controllerAs: 'ctrl',
      backdrop: 'static',
      keyboard: false,
      size: 'lg',
      windowClass: 'tour-modal',
      resolve: {
        customerId() {
          return $scope.adminRoles()[0];
        },
        segment() {
          return currentCustomer.$promise.then(function (customer) {
            return customer.settings.segment;
          });
        },
        features: ['$featuresService', function ($featuresService) {
          return $featuresService.getFeatures().$promise;
        }]
      }
    });
  }

  function shouldShowTour() {
    if ($scope.features && $scope.features.showOnboarding === false) {
      return false;
    }
    const isAdmin = $scope.adminRoles().length > 0;
    const finishedTour = $rootScope.userStatus.finishedTour;
    const didMobileOnboarding = $scope.currentUser.lastMobileLogin < $scope.currentUser.lastLogin || !$scope.currentUser.lastLogin && $scope.currentUser.lastMobileLogin;
    return isAdmin && !finishedTour && !didMobileOnboarding;
  }

  function getMenuItems() {
    return {
      top: [{
        'id': 'form',
        route: 'portal.customers.form.overview',
        active: 'portal.customers.form',
        icon: 'file-text',
        name: 'MENU_FORMS',
        disabled: () => !$rootScope.customer || !$rootScope.customer.$resolved || !hasPermissions([moreConstants.MANAGE_FORMS, moreConstants.MANAGE_REGISTRATIONS, moreConstants.MANAGE_TASKS, moreConstants.UPDATE_FOLDER, moreConstants.UPDATE_FOLDER, moreConstants.READ_SUBMISSION, moreConstants.UPDATE_SUBMISSION, moreConstants.DELETE_SUBMISSION, moreConstants.READ_REGISTRATION, moreConstants.DELETE_REGISTRATION, moreConstants.CREATE_TASK, moreConstants.READ_TASK, moreConstants.UPDATE_TASK, moreConstants.DELETE_TASK, moreConstants.CREATE_FORM, moreConstants.UPDATE_FORM, moreConstants.ARCHIVE_FORM, moreConstants.RESTORE_FORM, moreConstants.ADD_FORM, moreConstants.REMOVE_FORM, moreConstants.CREATE_FOLDER, moreConstants.UPDATE_FOLDER, moreConstants.DELETE_FOLDER]),
        requiredParams: ['customerId']
      }, {
        'id': 'templates',
        route: 'portal.customers.templates',
        active: 'portal.customers.templates',
        icon: 'file',
        name: 'MENU_TEMPLATES',
        disabled: () => !hasPermissions([moreConstants.MANAGE_TEMPLATES]),
        click() {
          $featureHelper.hasFeature(moreConstants.CUSTOM_TEMPLATES).then(result => {
            if (result) {
              $state.go(this.route);
            } else {
              $modalService.upgradePlanModal({
                title: 'FEATURE_CUSTOM_TEMPLATES',
                message: 'FEATURE_CUSTOM_TEMPLATES_MESSAGE',
                imgSrc: 'https://cdn.moreapp.com/features/en/feature-custom-templates.png'
              });
            }
          });
        },
        requiredParams: ['customerId']
      }, {
        'id': 'access',
        route: 'portal.customers.access.users',
        active: 'portal.customers.access',
        icon: 'users',
        name: 'MENU_USERS',
        disabled: () => !hasPermissions([moreConstants.MANAGE_USERS]),
        requiredParams: ['customerId']
      }, {
        'id': 'datasources',
        route: 'portal.customers.dataSources',
        active: 'portal.customers.dataSources',
        icon: 'download',
        name: 'MENU_DATA_SOURCES',
        disabled: () => !hasPermissions([moreConstants.MANAGE_DATA_SOURCES]),
        click() {
          $featureHelper.hasFeature(moreConstants.MANUAL_DATASOURCES).then(result => {
            if (result) {
              $state.go(this.route);
            } else {
              $modalService.upgradePlanModal({
                title: 'FEATURE_MANUAL_DATASOURCES',
                message: 'FEATURE_MANUAL_DATASOURCES_MESSAGE',
                imgSrc: 'https://cdn.moreapp.com/features/en/feature-data-sources.png'
              });
            }
          });
        },
        requiredParams: ['customerId']
      }, {
        'id': 'clients',
        route: 'portal.customers.clients.content',
        active: 'portal.customers.clients',
        icon: 'tablet',
        name: 'MENU_CLIENTS',
        disabled: () => false,
        requiredParams: ['customerId']
      }],
      bottom: [{
        'id': 'billing',
        route: 'portal.customers.billing.overview',
        active: 'portal.customers.billing',
        icon: 'credit-card',
        name: 'MENU_BILLING',
        disabled: () => !hasPermissions([moreConstants.MANAGE_CREDITS]),
      }, {
        'id': 'user',
        route: 'portal.customers.settings.general',
        active: 'portal.customers.settings',
        icon: 'user',
        requiredParams: ['customerId'],
        dynamicName: () => $scope.currentUser.firstName,
        subs: [{
          'id': 'settings',
          click(params) {
            $state.go('portal.customers.settings.general', params);
          },
          icon: 'cog',
          name: 'MENU_SETTINGS',
          disabled: () => !hasPermissions([
            moreConstants.MANAGE_ACCOUNT, // General
            moreConstants.MANAGE_CREDITS, // Billing
            moreConstants.CREATE_THEME, moreConstants.UPDATE_THEME, moreConstants.DELETE_THEME, // Themes
            moreConstants.MANAGE_USERS, // API keys
            moreConstants.MANAGE_FORMS, moreConstants.UPDATE_FORM, // Service accounts
            moreConstants.MANAGE_WEBHOOKS, moreConstants.READ_WEBHOOK, moreConstants.CREATE_WEBHOOK, moreConstants.UPDATE_WEBHOOK, moreConstants.DELETE_WEBHOOK, // Webhooks
            moreConstants.MANAGE_SSO,
            moreConstants.MANAGE_SENDING_DOMAIN,
          ]),
          requiredParams: ['customerId']
        }, {
          'id': 'logout',
          click: $scope.logout,
          icon: 'power-off',
          name: 'MENU_LOGOUT'
        }]
      }, {
        'id': 'help',
        icon: 'question-circle',
        name: 'MENU_HELP',
        click: () => $window.open('https://helpcenter.moreapp.com/'),
        disabled: () => $rootScope.features.showHelp === false,
      }]
    };
  }
}]);
